.without_footer {
  .main-div {
    margin-bottom: 0;
    height: calc(100vh - 95px);
    @include media1429 {
      height: calc(100vh - 95px);
    }
    @include media991 {
      height: calc(100vh - 70px);
    }
    @include media575 {
      height: calc(100vh - 140px);
      margin-top: 140px;
    }
   
  }
}

.development {
  .search-sidebar {
    // min-height: 100vh;
    width: 420px;
    height: 100%;
    position: fixed;
    // left: 0;
    top: 0;
    transition: width 0.1s ease, margin 0.1s ease-out;
    z-index: 9;
    margin-left: 0;
    background-color: $light-color;
    @include media1429 {
      width: 400px;
    }
    @include media991 {
      // margin-left: -505px;
      position: static;
      z-index: 999;
      padding: 0 15px 15px;
      background: $bg-color;
      // overflow-y: auto;
      // height: 100vh;
      width: 100%;
      height: auto;
    }
  }
  .show-dashboard {
    width: calc(100% - 420px);
    margin-left: 420px;
    display: flex;
    flex-direction: column;
    @include media1429 {
      width: calc(100% - 400px);
      margin-left: 400px;
    }
    @include media991 {
      width: 100%;
      margin-left: 0;
      padding: 15px;
      
    }
    h3 {
      @include media991 {
        margin-top: 15px;
      }
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
  li {
    list-style-type: none;
  }
  .btnprint {
    justify-content: flex-end;
    display: flex;
    flex-grow: 1;
    align-items: center;
    .btn {
      padding: 6px;
      border-radius: 8px;
      img {
        width: 25px;
        @include media480 {
          max-width: 75%;
          margin: 2px auto;
        }
      }
    }
  }
  .sidebartext {
    margin-top: 95px;
    @include media1429 {
      margin-top: 95px;
    }
    @include media991 {
      margin-top: 15px;
    }
    
  }
  .listgroup {
    &.selected {
      background-color: $gray-bg;
      .listdesc {
        span {
          background-color: $primary-color;
          color: $gray-bg;
          border-radius: 6px;
          font-size: 12px;
          padding: 2px 8px;
        }
      }
    }
    .listdesc {
      padding: 10px 0px 10px;
      margin: 0px 20px;
      border-bottom: 2px solid $bg-color;
      @include media991 {
        width: 120px;
        margin: 0px 10px;
        border-bottom: none;
      }
      @include media575 {
        padding: 15px 0px 15px;
      }
      span {
        background-color: $gray-bg;
        color: $primary-color;
        border-radius: 6px;
        font-size: 12px;
        padding: 2px 8px;
      }
      h4 {
        margin-top: 8px;
        margin-bottom: 5px;
        font-weight: bold;
        color: $black-color;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        color: $light-black;
        font-weight: 400;
        word-break: break-word;
      }
      .list-logos {
        display: flex;
        align-self: start;
        justify-content: space-between;
      }
    }
    &:last-child {
      .listdesc {
        border-bottom: none;
      }
    }
  }
  .group2 {
    border-left: 4px solid $bg-color;
    @include media575 {
      border-left: 0px;
    }
  }
  .grouptitle {
    border-bottom: 2px solid $bg-color;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    span.active {
      @include backgroundOpacity($primary-color, 0.1);
      border-radius: 8px;
      i {
        color: $primary-color;
      }
    }
    i.ifilter {
      padding: 10px;
      font-size: 13px;
      cursor: pointer;
      // @include media1429 {
      //     padding: 10px;
      //     font-size: 13px;
      // }
    }
  }
  .groupfilter {
    border-top: 5px solid $bg-color;
    @include media991 {
      border-top: none;
      display: block;
    }
    @include media575 {
      display: block;
    }
  }
  .groupactive {
    .listscroll {
      overflow-y: auto;
      height: calc(100vh - 365px);
      @include media1429 {
        height: calc(100vh - 365px);
      }
      @include media991 {
        height: auto;
      }
      
    }
  }
  .listscroll {
    overflow-y: auto;
    height: calc(100vh - 240px);
    
    @include media991 {
      height: auto;
      display: flex;
      // justify-content: center;
    }
    @include media575 {
      height: auto;
    }
  }
  .listscroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-color;
    background-color: $light-color;
    border-radius: 5px;
  }
  .listscroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 5px;
    background-color: $light-color;
  }
  .listscroll::-webkit-scrollbar-thumb {
    background-color: $border-color;
    border: 2px solid $border-color;
    border-radius: 5px;
    &:hover {
      background-color: $light-black;
      border: 2px solid $light-black;
    }
  }
  .filterdropdown {
    padding: 10px;
    justify-content: space-between;
    .select_box_inner {
      width: 50%;
      padding: 0 7px 0 0px;
    }
    .select_box {
      margin-left: 0px;
      margin-right: 0px;
    }
    .custom-select {
      background: $bg-light;
      border-radius: 8px;
      font-size: 14px;
      border: 0px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      option {
        padding: 15px;
        background-color: $light-color;
      }
      &:hover {
        border: 0px;
      }
    }
  }
  .custom-checkbox {
    background-color: $bg-light;
    border-radius: 8px;
    font-size: 14px;
    label {
      padding: 15px 40px;
      font-size: 14px;
    }
    .checkmark {
      top: 12px;
      left: 10px;
      height: 20px;
      width: 20px;
      background-color: $light-color;
    }
  }
  .serch-box {
    padding: 20px;
    @include media991 {
      background: $light-color;
      padding: 15px;
      border-radius: 4px;
      margin: 0;
    }
    .serch-btn {
      @include media991 {
        top: 15px;
        right: 15px;
      }
    }
  }

  ul.sub-menu {
    padding: 0;
    background-color: $light-color;
    white-space: nowrap;
    position: absolute;
    top: 40px;
    z-index: 1;
    opacity: 1;
    transform: translateY(30px);
    transition: all 0.5s ease 0s;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
    left: 0;
    padding-top: 9px;
    @include media991 {
      width: auto;
    }
    &:before {
      position: absolute;
      top: -16px;
      left: 12px;
      display: block;
      content: "";
      width: 0;
      border-style: solid;
      border-width: 0 10px 16px;
      border-color: transparent transparent $light-color;
      box-sizing: border-box;
      right: 0;
      height: 0;
    }

    > li {
      display: block;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;

      a {
        @include defaultFontStyle(500, 14, 1.2, $black-color);
        text-align: left;
        display: block;
        padding: 15px 0;
        margin: 0 15px;
        // border-bottom: 1px solid $menu-bg;
        position: relative;
        text-decoration: none;
        outline: none;
        text-transform: none;
        white-space: nowrap;
        transition: all 0.5s linear;
      }
      &.active {
        background-color: #e8edf1;
        color: $primary-color;
        border-radius: 0;
      }
      &:hover {
        background-color: #e8edf1;
        color: $primary-color;
        border-radius: 0;
      }
    }
  }
  .p-15 {
    padding: 15px;
  }
  table.tabledashed td {
    @include media480 {
      // min-width: 135px;
    }
  }
  .group1,
  .group2 {
    @include media991 {
      background: $light-color;
      border-radius: 4px;
      margin-top: 15px;
    }
  }
  .d-block {
    display: block;
  }
  .tabviewbtn li a {
    @include media480 {
      font-size: 11px;
    }
  }
  .mobilebutton {
    display: none;
    @include media991 {
      display: block;
    }
  }
  .chart-box {
    .w-50 {
      @include media1366 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
      }
    }
    .chart-select {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h3 {
        @include defaultFontStyle(600, 18, 1.4, $black-color);
        margin: 0 12px 0 0;
      }
      .customize-select {
        width: 30%;
        @include media1366 {
          width: 50%;
        }
        @include media480 {
          width: 100%;
        }
      }
    }
    .group-select-box {
      h4 {
        @include defaultFontStyle(400, 18, 1.4, $black-color);
        text-align: center;
        margin-bottom: 12px;
      }
      .chart-name-title {
        @include defaultFontStyle(400, 20, 1.4, $black-color);
        text-align: center;
        margin-bottom: 8px;
        margin-top: 8px;
      }
      .chart-name-disc {
        @include defaultFontStyle(400, 16, 1.4, $light-black);
        text-align: center;
        margin-bottom: 12px;
        margin-top: 12px;
      }
      p {
        text-align: center;
      }
      .group-select {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;

        .customize-select {
          width: 50%;
          margin: 0 8px;
        }
      }
    }
    .no-graph {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid $border-color;
      height: 500px;
    }
    .dispersion-info {
      h3 {
        @include defaultFontStyle(400, 18, 1.4, $black-color);
      }
      p {
        @include defaultFontStyle(500, 15, 1.5, $light-black);
      }
    }
    .table-responsive-xl {
      @include media1366 {
        margin-bottom: 24px;
      }
    }
    #groundChart,
    #groundChart1,
    #sprayAgainst,
    #sprayAgainst1 {
      height: 450px;
    }
    #hitterHeatMapA {
      svg {
        // width: 500px;
      }
    }
    
    .heatmap {
      border: 1px solid $border-color;
      border-radius: 8px;
      #pitcherHeatMapA {
        margin: 0 auto;
        max-width: 500px;
      }
      #pitcherHeatMapB {
        margin: 0 auto;
        max-width: 500px;
      }
      #hitterHeatMapA {
        margin: 0 auto;
        max-width: 500px;
      }
      #hitterHeatMapB {
        margin: 0 auto;
        max-width: 500px;
      }
    }
  }
  .filtersection {
    margin: 0 0 20px 0;
  }
}
.platedisc {
  .subtext {
    color: $light-black;
    font-weight: 500;
    padding-left: 16px;
    border-left: 2px solid $light-black;
    margin-left: 16px;
  }
  .othergroup {
    h3 {
      margin-top: 10px;
      @include media991 {
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .table thead th {
    vertical-align: middle;
  }
}
.chartinfo {
  margin-top: 30px;
}
.show-menu .development {
  .search-sidebar {
    margin-left: 0px;
    width: 100%;
  }
}
.developmentlist {
  .navbar_fixed {
    z-index: 9999;
    .overlay {
      display: none;
    }
  }
  .no-deta {
    height: calc(100vh - 95px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include media991{
      height: calc(100vh - 175px);
    }
    @include media575{
      height: calc(100vh - 245px);
    }
    h1 {
      @include defaultFontStyle(500, 30, 1.4, $black-color);
      margin-bottom: 10px;
    }
    p {
      @include defaultFontStyle(500, 15, 1.4, $light-black);
      max-width: 450px;
      text-align: center;
    }
  }
  .tabviewbtn-nodeta {
    height: calc(100vh - 258px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.filtersection{
  .umpire_name{
    font-weight: 500;
  }
}
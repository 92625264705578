$primary-font: 'Poppins', sans-serif;

$primary-color: #013662;
$light-color: #FFFFFF;
$black-color: #1B1B1B;
$black: #000000;
$light-black: #777F8F;
$gray-bg: #E8EDF1;
$bg-light: #F2F4F8;
$bg-color: #F6F9FE;
$border-color: #D8D8D8;
$hover-bg: #D0DAE2 ;

// table color
$bg-red: #FF4040;
$bg-blue: #888EF9;
$table-active: #DCE3EB;
$table-row: #F2F5F7;
$gray-light: #EEEEFE;
$table-pink: #FFA9A9;
$table-border: #E5E5E5;
$table-oasis: #fff2cc;
$table-white_smoke: #efefef;
$table-grey_80: #cccccc;
$table_border: #e9e9e9;

// chart color
$red-color: #FF1000;
$gray-dark: #B5B4B4;
$black-dark: #444444;
$highlight_color: #fdfd96a1;

// responsive view variables
// Small smartphones
$screen-350: 350px;
// Small smartphones
$screen-480: 480px;
// Small tablets and large smartphones (landscape view)
$screen-575: 575px;
// Small tablets (portrait view)
$screen-767: 767px;
// Tablets and small desktops
$screen-991: 991px;
// Large tablets and desktops
$screen-1199: 1199px;
// extra Large tablets and desktops
$screen-1366: 1366px;
//extra Large tablets and desktops
$screen-1429: 1429px;
// extra Large desktops
$screen-1599: 1599px;

/* for common fonts */
@mixin defaultFontStyle( $fontWeight: null, $fontSize: null, $lineHeight: null, $color: null) {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: ($fontSize)+px;
    line-height: $lineHeight;
    color: $color;
} 

@mixin backgroundOpacity($bgColor, $opacity: null) {
  background: rgba($bgColor, $opacity);
}

  // custom devices
  @mixin customMedia($custom) {
    @media (max-width: $custom+'px') {
      @content;
    }
  }
  // Small smartphones
  @mixin media350 {
    @media (max-width: #{$screen-350}) {
      @content;
    }
  }
  // Small devices
  @mixin media480 {
    @media (max-width: #{$screen-480}) {
      @content;
    }
  }
  // Small smartphones
  @mixin media575 {
    @media (max-width: #{$screen-575}) {
      @content;
    }
  }
  // Medium devices
  
  @mixin media767 {
    @media (max-width: #{$screen-767}) {
      @content;
    }
  }
  // tablet devices
  @mixin media991 {
    @media (max-width: #{$screen-991}) {
        @content;
    }
  }
  // large devices
  @mixin media1199 {
    @media (max-width: #{$screen-1199}) {
        @content;
    }
  }
  // Extra large devices
  @mixin media1366 {
    @media (max-width: #{$screen-1366}) {
        @content;
    }
  }
  // extra Large tablets and desktops
  @mixin media1429 {
    @media (max-width: #{$screen-1429}) {
        @content;
    }
  }
  // Extra large devices
  @mixin media1599 {
    @media (max-width: #{$screen-1599}) {
        @content;
    }
  }
.select_box {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .select_box_inner {
        &.w-25 {
            width: 25%;
            padding: 0 15px;
            @include media991 {
                width: 100%;
            }
        }
    }
}

.common-lable {
    margin: 20px 0 8px;
    display: block;
    text-align: left;
}

.custom-date {
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    appearance: none;
    font-size: 15px;
    text-align: left;
    background-color: #fff;
    &:hover,
    &:visited,
    &:active,
    &:focus {
        border: 1px solid $border-color;
        color: $light-black;
        outline: none;
    }
    @include media575 {
        padding: 5px;
    }
}
.select-box-rolling{
    width: 60%;
}
.custom-select {
    background: url("../../assets/images/common/gray_down_arrow.svg") no-repeat 96% center #fff;
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    appearance: none;
    overflow: hidden !important;
    @include defaultFontStyle(500, 15, 1.2, $light-black);
    &:hover,
    &:visited,
    &:active,
    &:focus {
        border: 1px solid $border-color;
        color: $light-black;
        outline: none;
    }
    option {
        @include defaultFontStyle(500, 15, 1.2, $black-color);
    }
}

.customize-select {
    appearance: none;
    @include defaultFontStyle(500, 15, 1.2, $light-black);
    option {
        @include defaultFontStyle(500, 15, 1.2, $black-color);
    }
    .css-yk16xz-control,
    .css-1pahdxg-control {
        border: 1px solid $border-color;
        min-height: 45px;
        height: 45px;
        box-shadow: none;
        &:hover,
        &:visited,
        &:active,
        &:focus {
            border-color: $black-color;
            color: $light-black;
            outline: none;
            box-shadow: none;
        }
        .css-g1d714-ValueContainer {
            position: initial;
        }
    }
    input {
        height: 100% !important;
    }
    .customize-select__menu {
        .customize-select__option {
            @include defaultFontStyle(500, 15, 1.2, $black-color);
            &:hover,
            &:visited,
            &:active,
            &:focus {
                background-color:rgba(1, 54, 98, 0.2);
            }
            &.css-9gakcf-option {
                background-color: $primary-color;
                color: $light-color;
            }
            &.css-1n7v3ny-option {
                background-color:rgba(1, 54, 98, 0.2);
            }
        }
    }
}

footer {
    position: fixed;
    left: 0;
    right: 0;
    padding: 10px 15px;
    background-color: $black-color;
    z-index: 9;
    bottom: 0;
    @include media991 {
        padding: 10px;
    }
    .d-flex {
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @include media480 {
            flex-direction: column;
        }
        .same_size {
          width: calc(100% / 3);   
            @include media767 {
                width: auto;
            }
        }
    }
    .social-icon {
        display: flex;
        align-items: center;
        li {
            margin: 0 5px;
            a {
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $black-color;
                font-size: 15px;
                border-radius: 100%;
                background-color: $light-color;
                @include media991 {
                    width: 30px;
                    height: 30px;
                    font-size: 13px;
                }
                &:hover {
                    background-color: $primary-color;
                    color: $light-color;
                }
            }
        }
    }
    a {
        @include defaultFontStyle (500, 14, 1.2, $light-color);
        text-align: center;
        @include media991 {
            font-size: 13px;
        }
        @include media575 {
            font-size: 11px;
        }
        @include media480 {
            order: 3;
            font-size: 12px;
        }
        &:hover {
            color: $border-color;
        }
    }
    .footer_url {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include media480 {
            margin: 10px 0;
        }
        a {
            margin-left: 15px;
            @include media991 {
                margin-left: 10px;
            }
            @include media575 {
                margin-left: 4px;
            }
            @include media480 {
                order: 2;
            }
        }
    }
}
.btn, .btn:focus, .btn:active, .btn:visited {
        box-shadow: none;
        display: inline-block;
        vertical-align: middle;
        padding: 10px 20px;
        border-radius: 8px;
        text-align: center;
        outline: none !important;
        cursor: pointer;
        @include defaultFontStyle(500, 16, 1.2, $primary-color);
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        @include media575 {
            padding: 10px 20px;
            font-size: 14px;
        }
    &.blue {
        background-color: $primary-color;
        color: $light-color;
        border: 1px solid $primary-color;
        &:hover {
            background-color: $hover-bg;
            color: $primary-color;
            border: 1px solid $hover-bg;
        }
    }
    &.trans {
        background-color: transparent;
        color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
            background-color: $hover-bg;
            color: $light-color;
            border: 1px solid $hover-bg;
        }
    }
    &.white {
        background-color: $light-color;
        color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
            background-color: $hover-bg;
            color: $primary-color;
            border: 1px solid $hover-bg;
        }
    }
}
.sidebar-wrapper {
  width: 240px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-transition: width 0.1s ease, margin 0.1s ease-out;
  transition: width 0.1s ease, margin 0.1s ease-out;
  z-index: 9;
  margin-left: 0;
  background-color: $light-color;
  @include media1429 {
    width: 240px;
  }
  @include media991 {
    margin-left: -240px;
    z-index: 999;
  }
  .sidebar-logo {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $bg-color;
    display: none;
    a {
      display: block;
      img {
        max-width: 240px;
        margin: 0 auto;
        @include media1429 {
          max-width: 160px;
        }
        @include media991 {
          max-width: 120px;
          margin-bottom: 6px;
        }
        @include media575 {
          max-width: 100px;
        }
      }
    }
    p {
      @include defaultFontStyle(600, 12, 1.5, $black-color);
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    @include media1429 {
      height: 100px;
    }
    @include media991 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 70px;
      align-items: center;
      justify-content: center;
    }
    @include media480 {
      height: 60px;
    }
  }
  .list-group {
    overflow-y: auto;
    height: 100vh;
    padding-top: 95px;
    padding-bottom: 55px;
    // @include media1429 {
    //   padding-top: 100px;
    // }
    @include media991 {
      padding: 0;
      height: calc(100vh - 70px);
    }
    @include media480 {
      height: calc(100vh - 60px);
    }
    li {
      display: block;
      background-color: transparent;
      transition: all 0.5s ease 0s;
      a {
        position: relative;
        display: block;
        padding: 16px 15px;
        text-align: left;
        margin: 0 20px;
        text-transform: capitalize;
        border-bottom: 2px solid $bg-color;
        transition: all 0.5s ease 0s;
        @include defaultFontStyle(500, 15, 1.2, $light-black);
        @include media1429 {
          padding: 15px 10px;
          margin: 0 15px;
          font-size: 15px;
        }
      }
      &.active {
        pointer-events: none;
        user-select: none;
      }
      &.active,
      &:hover {
        @include backgroundOpacity($primary-color, 0.09);
        a {
          color: $primary-color;
          font-weight: 500;
        }
      }
    }
  }
}

.list-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $light-color;
  background-color: $light-color;
  border-radius: 5px;
}
.list-group::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  background-color: $light-color;
}
.list-group::-webkit-scrollbar-thumb {
  background-color: $border-color;
  border: 2px solid $border-color;
  border-radius: 5px;
  &:hover {
    background-color: $light-black;
    border: 2px solid $light-black;
  }
}

body.show-menu {
  overflow: hidden;
  .sidebar-wrapper {
    margin-left: 0;
  }
  .overlay {
    position: fixed;
    left: 0;
    z-index: 99;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
  }
}

.gamesummary-section {
  .gamesummary-table {
    margin-bottom: 20px;

    @include media991 {
      margin-bottom: 15px;
    }

    table {
      &.tabledashed {
        td {
          // min-width: 200px;
        }
      }
    }
  }
}

.ground-chart {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 10px 15px;
  background-color: $light-color;
  position: relative;

  &.individual {
    margin-bottom: 30px;
  }

  h3 {
    @include defaultFontStyle(700, 18, 1.4, $black-color);
  }

  p {
    @include defaultFontStyle(400, 13, 1.4, $light-black);
    margin-bottom: 0;
    max-width: 156px;
    margin: 0 auto;
  }

  .groundChart {
    height: 424px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #groundChart {
    height: 424px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &.fixed-h {
    .groundChart {
      height: 367px !important;
    }
  }

  #groundChartAwayBatter {
    height: 424px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #groundChart1 {
    height: 543px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #sprayAgainst {
    height: 543px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #sprayAgainst1 {
    height: 543px;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.sprayChartNoInfoText {
  color: #667784;
  font-size: 15.5px;
  font-family: sans-serif;
}

.sprayChartCls {
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  .arc {
    cursor: pointer;
    opacity: 1;

    &:hover {
      opacity: 0.9;
    }
  }

  .arc-text {
    text-anchor: middle;
    pointer-events: none;
  }

  .tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    border: 1px solid #ccc;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 11px;
    pointer-events: none;
    padding: 2px 5px;
  }
}

#releaseStrikeChartPrintArea {
  .ground-chart {
    @include customMedia(1279) {
      width: 408px;
    }

    @include media575 {
      width: 100% !important;
    }
  }

  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #strikeZoneChart,
    #strikeZoneChartAwayPitcher,
    #releasePointChart,
    #releasePointChartAwayPitcher,
    #awayPitchSequencingChart,
    #homePitchSequencingChart {
      @include customMedia(1279) {
        width: 400px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }

}

#pitcherChartPrintArea {
  .ground-chart {
    @include customMedia(1279) {
      width: 408px;
    }

    @include media575 {
      width: 100% !important;
    }
  }

  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }
      }
    }

    .breakMovementChart {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100%;
      }
    }

    #breakMovementChart,
    #strikeZoneChart,
    #releasePointChart,
    #releasePointChartundefined,
    #sideReleaseChartundefined,
    #polarChartundefined {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

// #polarChartundefined{
//   @include media575 {
//     width: 60% !important;
//   }
// }

#chartPrintArea {
  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #umpireAnalysisChart {
      @include media1366 {
        width: 400px;
        margin: 0 auto;
      }

      @include media575 {
        width: 100% !important;
      }
    }

    #hartMapChart,
    .sprayChartCls,
    #groundDotChartForHitt,
    .ground-chart {
      @include customMedia(1279) {
        width: 400px;
        margin: 0 auto;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

.printLoader {
  background-color: white;
  position: fixed;
  height: calc(100% - 92px);
  // width: calc(100% - 195px);
  width: 1360px;
  z-index: 9999;

  @include customMedia(1600) {
    width: 100%;
  }

  .loadingWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #013662;

    .spinnerContainer {
      height: 40px;
    }

    .loadingText {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  @include customMedia(991) {
    // position: absolute;
    height: 100%;
    width: 100%;

    .loadingWrap {
      position: fixed;
      height: calc(100% - 70px);
    }
  }

  &.teamsummary_printLoader {
    width: 100%;
    height: calc(100vh - 95px);
    max-width: 1600px;
    margin-top: 95px;

    @include customMedia(991) {
      margin-top: 70px;
      height: calc(100vh - 70px);
    }

    @include customMedia(575) {
      margin-top: 140px;
      height: calc(100vh - 140px);
    }
  }

  &.teamsummary_loader {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin-top: 0;
    left: 0;
  }

  &.modal-printloader {
    background-color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    z-index: 9999999999999;
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 9;

    ul {
      list-style: none;

      li {
        @include defaultFontStyle(500, 16, 1.2, #28263d);
        padding: 6px;
        cursor: pointer;
      }
    }
  }

  &:hover {
    .dropdown-content {
      display: block;
      transition: 0.5s;
    }
  }

  .printer {
    width: 41px;
    height: 41px;
    border: 1px solid #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
  }
}

.singleDiv {
  // flex-wrap: nowrap !important;
}

.breakDiv {
  // flex-wrap: wrap !important;
}

.breakDiv {
  @include media1429 {
    &.row {
      flex-wrap: wrap !important;
      margin: 0;

      .w-33 {
        width: 50% !important;
        margin-bottom: 15px;

        #releasePointChart,
        #strikeZoneChart,
        #homePitchSequencingChart,
        #releasePointChartAwayPitcher,
        #strikeZoneChartAwayPitcher,
        #awayPitchSequencingChart {
          @include media1429 {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.table-title {
  margin-top: 20px;
}

#releaseStrikeChartPrintArea {
  .ground-chart {
    @include customMedia(1279) {
      width: 408px;
    }

    @include media575 {
      width: 100% !important;
    }
  }

  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #strikeZoneChartPitcher,
    #releasePointChartPitcher,
    #sideReleasePointChartPitcher,
    #strikeZoneChartAwayPitcher,
    #releasePointChartAwayPitcher,
    #sideReleasePointChartAwayPitcher,
    #strikeZoneChartHomePitcher,
    #releasePointChartHomePitcher,
    #sideReleasePointChartHomePitcher {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

#sequencingChartPrintArea,
#GameStrikeChartPrintArea {
  .ground-chart {
    @include customMedia(1279) {
      width: 408px;
    }

    @include media575 {
      width: 100% !important;
    }
  }

  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #PitchSequencingChartAwayHitter,
    #countSequencingChartAwayHitter,
    #resultSequencingChartAwayHitter,
    #PitchSequencingChartAwayPitcher,
    #countSequencingChartAwayPitcher,
    #resultSequencingChartAwayPitcher,
    #PitchSequencingChartHomeHitter,
    #countSequencingChartHomeHitter,
    #resultSequencingChartHomeHitter,
    #PitchSequencingChartHomePitcher,
    #countSequencingChartHomePitcher,
    #resultSequencingChartHomePitcher,
    #PitchSequencingChartPitcher,
    #countSequencingChartPitcher,
    #resultSequencingChartPitcher,
    #PitchSequencingChartHitter,
    #countSequencingChartHitter,
    #resultSequencingChartHitter,
    #strikeZoneChartPitcherSwings,
    #strikeZoneChartPitcherWhiffs,
    #strikeZoneChartPitcherBallsInPlay,
    #strikeZoneChartHomePitcherSwings,
    #strikeZoneChartHomePitcherWhiffs,
    #strikeZoneChartHomePitcherBallsInPlay,
    #strikeZoneChartAwayPitcherSwings,
    #strikeZoneChartAwayPitcherWhiffs,
    #strikeZoneChartAwayPitcherBallsInPlay {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

#umpireAnalysisArea {
  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #calledPitchesChart,
    #edgeCallsChart,
    #contourGraphChart {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

#movementChartPrintArea {
  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #longBreakMovementChartHomePitcher,
    #shortBreakMovementChartHomePitcher,
    #polarChartchartHomePitcher,
    #longBreakMovementChartAwayPitcher,
    #shortBreakMovementChartAwayPitcher,
    #polarChartchartAwayPitcher,
    #longBreakMovementChartPitcher,
    #shortBreakMovementChartPitcher,
    #polarChartchartPitcher {
      @include customMedia(1279) {
        width: 376px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

#AtBatChartPrintArea {
  margin-top: 30px;

  .ground-chart {
    @include customMedia(1279) {
      width: 408px;
    }

    @include media575 {
      width: 100% !important;
    }
  }

  .common-charts {
    .row {
      justify-content: center;

      @include customMedia(1279) {
        justify-content: unset;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #strikeZoneChartHitter0,
    #groundChartHitter0,
    #strikeZoneChartHitter1,
    #groundChartHitter1,
    #strikeZoneChartHitter2,
    #groundChartHitter2,
    .ground-chart {
      @include customMedia(767) {
        width: 408px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

// Scrimmage Summary(ss) css

.ss-summary-result-table {
  width: 100%;
  padding: 0 15px;

  table {
    tr {
      td {
        &:first-child {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }
    }
  }
}

.blast-summary-result-table {
  width: 100%;

  table {

    td,
    th {
      border-right: 1px solid $table-border;
      padding: 15px !important;

      &:last-child {
        border-right: none;
      }
    }
  }

  .custom-header-fixed {
    height: 500px;

    thead {
      position: sticky;
      top: -1px;
      z-index: 9;
      background-color: #ffffff;
    }
  }
}

.ss-pitchlog-table {
  .ant-table-cell-fix-left {
    @include media575 {
      position: static !important;
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    @include media767 {
      position: static !important;
    }
  }

  table {
    tr {
      th {
        &:nth-child(3) {
          @include media767 {
            position: sticky !important;
            left: 0;
          }
        }
      }

      td {
        &:nth-child(3) {
          @include media767 {
            position: sticky !important;
            left: 0;
          }
        }
      }
    }
  }
}

.ss-pitcher-summary-table,
.ss-pitcher-characteristics-table,
.ss-pitcher-platediscipline-table,
.ss-hitter-summary-table,
.ss-hitter-platediscipline-table {
  table {
    tr {
      th {
        &:first-child {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }

      td {
        &:first-child {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }
    }
  }
}

.ss-pitcher-battedballs-table,
.ss-hitter-battedball-table {
  table {
    tr {
      th {
        &:nth-child(2) {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }

      td {
        &:nth-child(2) {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }
    }
  }
}

.ss-hitter-atbats-table {
  table {
    tr {
      th {
        &:nth-child(3) {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }

      td {
        &:nth-child(3) {
          @include media767 {
            position: sticky;
            left: 0;
          }
        }
      }
    }
  }
}

#sessionSummaryChart {
  .select-group {
    display: flex;

    @include media575 {
      display: block;
    }

    .select-box {
      display: flex;
      margin: 5px;

      @include media575 {
        display: block;
        width: 100%;
      }

      select {
        background: url("../../assets/images/common/gray_down_arrow.svg") no-repeat 92% center $light-color;
        padding: 10px 17px 10px;

        @include media1366 {
          max-width: 100% !important;
        }
      }
    }
  }

  .common-charts {
    .row {
      @include customMedia(1279) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-y: auto;
      }

      @include media575 {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-y: hidden;
      }

      .w-33 {
        @include customMedia(1279) {
          width: auto;
        }

        @include media575 {
          width: 100% !important;
        }
      }
    }

    #strikeZoneChartfirst,
    #strikeZoneChartsecond,
    #strikeZoneChartthird {
      @include customMedia(1279) {
        width: 400px;
      }

      @include media575 {
        width: 100% !important;
      }
    }
  }
}

.select-group-white {
  display: flex;

  @include media575 {
    display: block;
  }

  .select-box {
    display: flex;
    margin: 5px;

    @include media575 {
      display: block;
      width: 100%;
    }

    select {
      background: url("../../assets/images/common/gray_down_arrow.svg") no-repeat 92% center $light-color;
      padding: 10px 17px 10px;

      @include media1366 {
        max-width: 100% !important;
      }
    }
  }
}

.chart-ifream {
  position: relative;

  .ifream-box {
    position: absolute;
    width: 75px;
    height: 75px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 3px;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 8px 0 0 0;

    &.ifream-rolling {
      left: 283px;
      border-radius: 0;

      @include media480 {
        left: unset;
        right: 0;
        border-radius: 0 8px 0 0;
      }
    }

    iframe {
      border-radius: 8px 0 0 0;
    }

    .bollr-webgl {
      position: relative;

      .loader-bg {
        border-radius: 8px 0 0 0;
        background-color: rgba(0, 0, 0, 0.212);
        position: absolute;
        top: 0;
        left: 0;
        height: 75px;
        width: 75px;
      }

      .loader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid $primary-color;
        width: 30px;
        height: 30px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
        margin: 24px auto;
      }

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}


#developmentPrintArea {
  .row {
    justify-content: center;

    @include media1366 {
      display: block;
      display: flex;
      flex-direction: column;
      max-width: 438px;
      margin: auto;
    }

    .w-38 {
      width: 39%;
      padding: 0 15px;

      @include media1366 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
      }
    }
  }
}

#pitchR-iframe {
  width: 100%;

  &.Chart-iframe {
    margin-top: 35px;
  }

  .pitchr-webgl {
    width: 100%;
  }

  iframe {
    height: 456px !important;
    width: 100%;
  }
}

.teamsummary-table {
  .custom-header-fixed {
    max-height: 630px;

    thead {
      position: sticky;
      top: -1px;
      z-index: 9;
      background-color: #ffffff;

      tr {
        th {
          &:first-child {
            border-right: none;
          }

          &:nth-child(2) {
            position: sticky !important;
            z-index: 3;
            left: 120px;
            top: -1px;
            border-right: 1px solid $table-border;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            border-right: none;
            border-left: none;
            z-index: 3;
          }

          &:nth-child(2) {
            border-right: 1px solid #E5E5E5;
            z-index: 3;
          }
        }
      }
    }
  }

  table {
    table {
      tbody {
        tr {
          td {
            &:nth-child(2) {
              border-left: none;
              position: sticky !important;
              z-index: 2;
              left: 120px;
              top: -1px;
              background: #fff;
              border-bottom: 1px solid #E5E5E5;
            }
          }
        }
      }
    }
  }
}

.parentTable {
  table {
    tbody {
      .ant-table-expanded-row {
        &.ant-table-expanded-row-level-1 {
          >td {
            padding: 0px !important;
          }
        }
      }
    }

    tr {
      display: grid;
      grid-template-columns: 120px 35px 93px 70px 77px 77px 77px 107px 71px 71px 66px 66px 66px 70px 66px 68px 70px 83px 68px 70px 83px 68px 83px;

      &.ant-table-expanded-row>td {
        border: none !important;
      }

      td {
        min-height: 48px;
        padding: 12px 10px !important;

        a,
        p,
        div {
          line-height: 24px;
        }

        span {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }
      }
    }
  }
}

.parentTable_plate_discipline {
  table {
    tbody {
      .ant-table-expanded-row {
        &.ant-table-expanded-row-level-1 {
          >td {
            padding: 0px !important;
          }
        }
      }
    }

    tr {
      display: grid;
      grid-template-columns: 120px 35px 78px 62px 99px 136px 103px 107px 107px 103px 100px 149px 166px 111px 179px;

      &.ant-table-expanded-row>td {
        border: none !important;
      }

      td {
        min-height: 48px;
        padding: 12px 10px !important;

        a,
        p,
        div {
          line-height: 24px;
        }

        span {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }
      }
    }
  }
}

.parentTable_pitch_metrics {
  table {
    tbody {
      .ant-table-expanded-row {
        &.ant-table-expanded-row-level-1 {
          >td {
            padding: 0px !important;
          }
        }
      }
    }

    tr {
      display: grid;
      grid-template-columns: 120px 35px 104px 60px 118px 126px 117px 123px 69px 78px 108px 128px 108px 143px 143px;

      &.ant-table-expanded-row>td {
        border: none !important;
      }

      td {
        min-height: 48px;
        padding: 12px 10px !important;

        a,
        p,
        div {
          line-height: 24px;
        }

        span {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }
      }
    }
  }
}

.team_sec {
  >div {
    >div {
      >div {
        >div {
          >div {
            >table {
              >.ant-table-tbody {
                >tr {
                  &:last-child {
                    >td {
                      &:nth-child(2) {
                        background: lightgray;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.nestedTable {
  >div {
    >div {
      >div {
        width: 100%;
        margin-left: 0px !important;
        margin-right: 0px !important;

        table {
          tbody {
            tr {
              td {
                &:first-child {
                  position: sticky !important;
                  left: 0;
                  z-index: 2;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

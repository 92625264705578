.teamsummary {
    .btn.btnhead {
        &:hover {
            background-color: $primary-color;
            color: $light-color;
            border: 1px solid $primary-color;
        }
        @include media575 {
            padding: 12px 8px;
            font-size: 12px;
            height: 40px;
        }
    }
    .rc-slider {
        display: flex;
        height: 45px;
        align-items: center;
        margin: 0 8px;
        .rc-slider-handle {
            border: solid 5px $primary-color;
            width: 16px;
            height: 16px;
            margin-top: 0px;
        }
        .rc-slider-track {
            background-color: $primary-color;
            height: 6px;
        }
        .rc-slider-step {
            height: 6px;
        }
        .rc-slider-tooltip-inner {
            background-color: $black-color;
            padding: 2px;
            min-width: 35px;
            height: 22px;
            @include defaultFontStyle(500, 14, 1.4, $light-color);
        }
        .rc-slider-tooltip-arrow {
            border-top-color: $black-color;
        }
        .rc-slider-tooltip-placement-top {
            top: -23px;
        }
        .rc-slider-handle-dragging {
            border-color: $primary-color !important;
            box-shadow: none !important;
        }
    }
    .filterinfo {
        .select_box {
            .d-inline-flex {
                @include media575 {
                    display: block;
                }
            }
            .mr-15 {
                @include media575 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .btnprint{
        .btn{
            margin-left: 12px;
            padding: 7px 10px;
            @include media767 {
                padding: 5px;
            }
            img{
                margin-right: 0;
                width: 25px;
            }
        }
    }
}

.teambanner {
    background: url("../../assets/images/teamsummary/banner.png") no-repeat 96% center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    min-height: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    @include media767 {
        background-position: center;
        min-height: 200px;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        z-index: -1;
    }
    .logo {
        max-width: 61px;
        height: 61px;
        margin: 0 auto;
        img {
            @include backgroundOpacity($black-color, 0.4);
            padding: 10px 10px;
            border-radius: 50%;
            margin-bottom: 8px;
            width: 100%;
            height: 100%;
        }
    }
    h1 {
        @include defaultFontStyle(700, 35, 1.4, $light-color);
        @include media767 {
            font-size: 24px;
        }
    }
}

body.show-menu.teamsummarylist .overlay {
    display: none;
}

.page-content-wrapper {
    min-height: 100vh;
    width: calc(100% - 240px);
    margin-left: 240px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    @include media1429 {
        width: calc(100% - 240px);
        margin-left: 240px;
    }
    @include media991 {
        width: 100%;
        margin-left: 0;
    }
    &.tabactive {
        width: 100%;
        margin-left: 0px;
    }
    .navbar_fixed {
        // width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        background: $light-color;
        border-bottom: 2px solid $bg-color;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding: 0;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 99;
        transition: width 0.1s ease, left 0.1s ease;
        height: 95px;
        padding: 15px 0;
        padding-right: 30px;
        @include media1429 {
            height: 95px;
            padding: 0;
            padding-right: 30px;
        }
        @include media991 {
            padding: 0 15px;
            height: 70px;
        }
        @include media575{
            height: 140px;
        }
        // @include media480 {
        //     height: 60px;
        // }
        .navbar-logo {
            width: 270px;
            @include media1429 {
                width: 240px;
            }
            @include media991 {
                width: 160px;
            }
            // @include media991 {
            //     display: none;
            // }
            @include media575{
                // display: flex;
                // justify-content: center;
                width: auto;
                margin-bottom: 2px;
            }
            a {
                display: block;
                img {
                    max-width: 150px;
                    margin: 0 auto;
                    @include media1429 {
                        max-width: 140px;
                    }
                    @include media991{
                        width: 100px;
                    }
                    @include media575{
                        width: 100px;
                    }
                }
            }
            p {
                @include defaultFontStyle(600, 16, 1.5, $black-color);
                text-transform: capitalize;
                text-align: center;
                margin: 8px 8px 0;
                @include media1429 {
                    font-size: 14px;
                }
            }
        }
        .menu-toggle {
            display: none;
            cursor: pointer;
            @include media991 {
                display: block;
                align-items: center;
                justify-content: center;
            }
            .menu-icon {
                > span {
                    position: relative;
                    width: 20px;
                    height: 2px;
                    background-color: $black-color;
                    display: inline-block;
                    vertical-align: middle;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    top: 50%;
                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 2px;
                        background-color: $black-color;
                        right: 0;
                        -webkit-transition: all 0.5s;
                        -o-transition: all 0.5s;
                        transition: all 0.5s;
                    }
                    &:before {
                        top: -6px;
                    }
                    &:after {
                        top: 6px;
                    }
                }
            }
        }
        .tab-view {
            list-style: none;
            li {
                position: relative;
                display: inline-block;
                list-style: none;
                vertical-align: middle;
                margin: 0 0 0 30px;
                transition: all 0.5s ease 0s;
                @include media991 {
                    margin-left: 15px;
                    padding: 3px 0;
                }
                &:before {
                    content: "";
                    position: absolute;
                    height: 4px;
                    width: 20px;
                    border-radius: 9px 9px 0px 0px;
                    background-color: $primary-color;
                    bottom: -15px;
                    margin: 0 auto;
                    opacity: 0;
                    left: 0;
                    right: 0;
                    transition: all 0.5s ease 0s;
                    @include media767{
                        height: 2px;
                        bottom: -2px;
                    }
                }
                a {
                    @include defaultFontStyle(500, 16, 1.2, $light-black);
                    @include media991 {
                        font-size: 14px;
                    }
                }
                &.active,
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                    a {
                        color: $black-color;
                    }
                }
            }
        }
        .main-search {
            margin: 0 0 0 auto;
            position: relative;
            @include media575 {
                margin: 0;
            }
            .search-button {
                display: none;
                @include media575 {
                    height: 20px;
                    display: flex;
                    width: 20px;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-weight: 500;
                        font-size: 16px;
                        color: $black-color;
                    }
                }
            }
            .serch-box {
                width: 300px;
                @include media767 {
                    width: 260px;
                }
                // @include media575 {
                //     height: 48px;
                //     position: absolute;
                //     right: 0;
                //     bottom: 0;
                //     top: calc(100% + 26px);
                //     opacity: 0;
                //     visibility: hidden;
                //     transition: all 0.2s;
                //     transform: translateX(30px);
                //     opacity: 0;
                //     @include media480 {
                //         top: calc(100% + 21px);
                //         height: 40px;
                //     }
                //     input {
                //         border: 1px solid $border-color;
                //     }
                // }
                .css-64ioxx-control {
                    height: 48px;
                    padding: 0 5px 0 10px;
                    @include media991 {
                        height: 38px;
                    }
                    @include media575 {
                        border: 1px solid $border-color;
                    }
                }
                .css-1okebmr-indicatorSeparator {
                    display: none;
                }
            }
            &.active {
                @include media575 {
                    .serch-box {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0);
                    }
                    .search-button {
                        i {
                            font-size: 18px;
                            &:before {
                                content: "\f00d";
                            }
                        }
                    }
                }
            }
        }
        @include media575 {
            .tab-dropdown {
                margin: 0 auto;
                .custom-select {
                    background: url("../../assets/images/common/gray_down_arrow.svg") no-repeat 95% center $bg-color;
                    box-shadow: none;
                    height: 100%;
                    width: 100%;
                    padding: 10px 40px 10px 20px;
                    border: 1px solid $bg-color;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    vertical-align: middle;
                    appearance: none;
                    @include defaultFontStyle(500, 15, 1.2, $light-black);
                    &:hover,
                    &:visited,
                    &:active,
                    &:focus {
                        border: 1px solid $bg-color;
                        color: $light-black;
                        outline: none;
                    }
                    option {
                        @include defaultFontStyle(500, 15, 1.2, $black-color);
                    }
                }
            }
        }
    }
    .menu-group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        @include media575{
            display: block;
            text-align: center;
        }
        .serch-box{
            @include media575{
                margin-top: 10px !important;
                margin: auto;
            }
        }
    }
}

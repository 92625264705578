body {
  background-color: $bg-color;
  @include defaultFontStyle(500, 16, 1.2, $black-color);
  margin: 0;
  overflow: hidden;
}

body p {
  @include defaultFontStyle(500, 15, 1.5, $black-color);
  margin: 0 0 20px;
  padding: 0;
  @include media991 {
    @include defaultFontStyle(500, 14, 1.5, $black-color);
  }
  @include media480 {
    @include defaultFontStyle(500, 13, 1.5, $black-color);
    margin: 0 0 15px;
  }
}

section {
  padding: 20px;
  // @include media991 {
  //   padding: 15px;
  // }
}

.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.white-bg {
  background-color: $light-color;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(40, 38, 61, 0.03);
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.m-0 {
  margin: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.m-auto {
  margin: auto;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.w-100 {
  width: 100% !important;
}

table {
  border-collapse: collapse !important;
  width: 100%;
  &.with-border {
    thead th {
      border-right: 1px solid $table-border;
      padding: 18px 10px !important;
      vertical-align: middle;
      font-size: 15px;
      // min-width: 88px;
      background-color: $primary-color;
      @include media1599 {
        padding: 15px 10px !important;
        // min-width: 140px;
        vertical-align: middle;
      }
      &.user_name {
        min-width: 175px;
        @include media1599 {
          min-width: 200px;
        }
      }
      .headimage {
        margin-left: 3px;
      }
    }
    td {
      border-bottom: 1px solid $table-border;
      border-right: 1px solid $table-border;
      border-left: 1px solid $table-border;
      padding: 12px 10px !important;
      font-size: 15px;
    }
    .icon {
      margin-right: 5px;
      width: 25px;
      height: 25px;
    }
  }
  &.tabledashed {
    border-collapse: separate !important;
    border-spacing: 1px 5px;
    .tablerowgray {
      background-color: $table-active;
      color: $black-color;
    }
    .tablerowblue {
      background-color: $primary-color;
      color: $light-color;
    }
    td {
      border-right: 1px solid $bg-color;
      padding: 15px !important;
      font-size: 15px;
      @include media1429 {
        // min-width: 130px;
      }
      &:last-child {
        border-right: none;
        border-radius: 0 10px 10px 0;
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
        position: sticky;
        left: 0;
      }
      &.tablerowgray {
        border-radius: 0;
      }
      &.fw-500 {
        font-weight: 700;
      }
      > img {
        display: inline-block;
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }
    }
    th {
      border-right: 1px solid $bg-color;
      padding: 15px !important;
      font-size: 15px;
      &:last-child {
        border-right: none;
      }
      &.pitch-outcomes {
        &:first-child {
          border-radius: 10px 0 0 10px;
          position: sticky;
          left: 0;
        }
      }
      > img {
        display: inline-block;
        margin-left: 5px;
        width: 18px;
        height: 22px;
      }
    }
  }
}

.table-responsive-xl {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 5px;
}
.table-responsive-xl::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $light-color;
  background-color: $light-color;
  border-radius: 5px;
}
.table-responsive-xl::-webkit-scrollbar {
  height: 7px;
  width: 100%;
  border-radius: 5px;
  background-color: $light-color;
}
.table-responsive-xl::-webkit-scrollbar-thumb {
  background-color: $border-color;
  border-bottom: 2px solid $border-color;
  border-radius: 5px;
  &:hover {
    background-color: $light-black;
    border-bottom: 2px solid $light-black;
  }
}

.table thead th {
  vertical-align: bottom;
  background-color: $primary-color;
  color: $light-color;
  &:first-child {
    border-radius: 10px 0 0 0px;
    border-left: none;
  }
  &:last-child {
    border-radius: 10px 10px 0 0px;
  }
  &.one-child {
    border-radius: 10px 10px 0 0;
  }
  &.pitchTable{
    border-radius: 0px 10px 0 0px;
  }
}
.table td,
.table th {
  vertical-align: middle;
  padding: 15px;
  text-align: center !important;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.validMsg {
  margin-top: 5px;
  display: block;
  @include defaultFontStyle(600, 13, 1.2, $black-color);
}

.main-div {
  margin-top: 95px;
  margin-bottom: 55px;
  overflow-y: auto;
  height: calc(100vh - 95px);
  // @include media1429 {
  //   margin-top: 95px;
  //   height: calc(100vh - 100px);
  // }
  @include media991 {
    margin-top: 70px;
    margin-bottom: 50px;
    height: calc(100vh - 70px);
  }
  @include media575 {
    margin-bottom: 00px;
    margin-top: 140px;
    height: calc(100vh - 140px);
  }
  // @include media480 {
  //   margin-bottom: 100px;
  //   margin-top: 60px;
  //   height: calc(100vh - 100px);
  // }
  section {
    .container-fluid {
      .homebatters-table,
      .awaybatters-table,
      .pitcherssummary-table {
        padding-bottom: 30px;
        @include media991 {
          padding-bottom: 15px;
        }
      }
      &:last-child {
        .homebatters-table,
        .awaybatters-table,
        .pitcherssummary-table {
          padding-bottom: 0;
        }
      }
    }
  }
}

.main-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $light-color;
  background-color: $light-color;
  border-radius: 5px;
}
.main-div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $light-color;
}
.main-div::-webkit-scrollbar-thumb {
  background-color: $light-black;
  border: 2px solid $light-black;
  border-radius: 5px;
  &:hover {
    background-color: $light-black;
    border: 2px solid $light-black;
  }
}
.flex-wrap{
  flex-wrap: wrap;
}
.d-flex {
  display: -ms-flexbox;
  display: flex;
}
.mr-15 {
  margin-right: 15px;
}
h3 {
  @include defaultFontStyle(700, 20, 1.4, $black-color);
  margin-bottom: 15px;
  @include media575 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

h2 {
  @include defaultFontStyle(700, 24, 1.4, $black-color);
  margin-bottom: 15px;
  @include media575 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
.bg-redlight {
  @include backgroundOpacity($bg-red, 0.5);
}
.bg-reddark {
  @include backgroundOpacity($bg-red, 0.7);
}
.bg-graylight {
  background-color: $gray-light;
}
.bg-blue {
  background-color: $bg-blue;
}
.bg-white {
  background-color: $light-color;
}
.bg-bluelight {
  @include backgroundOpacity($bg-blue, 0.5);
}
.bg-tablepink {
  background-color: $table-pink;
}
.bg-tablelightpink {
  @include backgroundOpacity($table-pink, 0.7);
}
.bg-tablelightpinkshade {
  @include backgroundOpacity($table-pink, 0.2);
}
.bg-red {
  background-color: $bg-red;
  &.color-w {
    color: $light-color;
  }
}
.shadow-none {
  box-shadow: none !important;
}
.table-redlight {
  @include backgroundOpacity($bg-red, 0.8);
  &.color-w {
    color: $light-color;
  }
}
.table-bluelight {
  @include backgroundOpacity($bg-blue, 0.3);
}
.team-text-align{
  align-items: center;
  @include media575{
    align-items: flex-start;
  }
}
.filtersection {
  margin: 20px 0;
  .hitting-pitching{
    @include media575{
      display: block;
    }
    .btn{
      font-size: 14px;
      @include media575{
        margin-bottom: 10px;
      }
    }
  }
  &.hitting-pitching-btn{
    h3{
      margin-bottom: 0;
    }
    .hitting-pitching{
      flex-wrap: wrap;
    }
    button{
      padding: 6px 23px;
      font-size: 15px;
      border-radius: 20px;
      @include media767{
        margin-bottom: 15px !important;
      }
      @include media575 {
        padding: 5px 15px !important;
        font-size: 14px !important;
        height: 40px !important;
        margin-bottom: 10px;
      }

    }
  }
  @include media767 {
    margin: 15px 0;
  }
  h3 {
    margin: 0 12px 0 0;
    @include media767 {
      margin-bottom: 15px;
    }
  }
  .filterinfo {
    padding: 15px 30px 40px;
    margin-top: 30px;
    @include media767 {
      padding: 0px 15px 15px;
      margin-top: 15px;
    }
    .w-25 {
      width: 25%;
      padding: 0 15px;
      @include media991 {
        width: 100%;
      }
    }
  }
  .filtertags {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 6px 10px;
    font-size: 13px;
    margin-right: 12px;
    display: inline-block;
    margin-bottom: 10px;
    @include media991 {
      margin-bottom: 4px;
    }

    @include media575 {
      border-radius: 16px;
    }
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .formbutton {
    margin-top: 25px;
  }
  .d-flex {
    flex-wrap: wrap;

    @include media767 {
      display: block;
    }
  }
  .w-70{
    width: 70%;
  }
  .w-30{
    width: 30%;
  }
  .d-inline-flex {
    display: inline-flex;
    width: 100%;
  }
  .formbutton {
    @include media767 {
      text-align: center;
      margin-top: 15px;
    }
    button {
      width: 145px;
      @include media480 {
        width: 100px;
      }
    }
  }
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.d-inline {
  display: inline-block;
}
.btnfilter {
  justify-content: flex-end;
  display: flex;
  flex-grow: 1;
}
.d-sm-none {
  @include media767 {
    display: none !important;
  }
}
.d-md-none {
  @include media991 {
    display: none !important;
  }
}
.d-none {
  display: none;
}
.d-sm-block {
  @include media767 {
    display: flex;
  }
}
.table-rowgray {
  background-color: $table-row;
}
.table-rowwhite {
  background-color: $light-color;
}
.table-rowoasis {
  background-color: $table-oasis;
  color: $black-color;
}
.table-rowwhitesmoke {
  background-color: $table-white_smoke;
  color: $black-color;
}
.table-rowgrey80 {
  background-color: $table-grey_80;
  color: $black-color;
}
.d-grid {
  display: grid;
}

.serch-box {
  position: relative;
  .form-control {
    padding-right: 50px;
  }
  input {
    background: $bg-light;
    border-radius: 8px;
    width: 100%;
    border: 0;
    padding: 10px;
    @include defaultFontStyle(400, 15, 1.2, $black-color);
    height: 48px;
    @include media480 {
      height: 40px;
    }
    &.bgwhite {
      background-color: $light-color;
      border: 1px solid $border-color;
    }
  }
  .serch-btn {
    position: absolute;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    @include media480 {
      height: 40px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}

// square checkbox custom
.custom-checkbox {
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: -10px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $primary-color;
    border-radius: 4px;
  }
  label input:checked ~ .checkmark {
    background-color: $primary-color;
    border-radius: 4px;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  label input:checked ~ .checkmark:after {
    display: block;
  }
  label .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid $light-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.pr-0 {
  padding-right: 0px;
}
.mt-15 {
  margin-top: 15px;
}

.common-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @include media991 {
    margin-bottom: 15px;
  }
  &.withsearch {
    @include media480 {
      display: block;
    }
    .printer {
      @include media575 {
        width: 50px;
        height: 47px;
      }
    }
    .right-side {
      @include media480 {
        justify-content: space-between;
      }
      .serch-box {
        @include media480 {
          width: 90%;
        }
      }
    }
  }
  .right-side {
    @include media480 {
      margin-top: 10px;
    }
  }
  h2,
  h3 {
    margin-bottom: 0;
  }
  .right-side {
    display: flex;
    align-items: center;
    .serch-box {
      margin-right: 15px;
      width: 300px;
      input {
        background-color: $light-color;
        border: 1px solid $border-color;
        height: 50px;
      }
      .serch-btn {
        height: 50px;
      }
    }
    .btnfilter {
      margin-left: 15px;
    }
  }
  .printer {
    width: 41px;
    height: 41px;
    border: 1px solid $black-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    background-color: $light-color;
    @include media767 {
      width: 41px;
      height: 41px;
    }
    @include media575 {
      width: 42px;
      height: 39px;
    }
    @include media480 {
      width: 42px;
      height: 39px;
    }
  }
}

.w-50 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  @include media991 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
// .w-100 {
//   flex: 0 0 100%;
//   max-width: 100%;
//   padding: 0 15px;
// }

.common-charts {
  background-color: $light-color;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 10px 30px rgba(40, 38, 61, 0.03);
  margin-bottom: 20px;
  position: relative;
  h3.player-name {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 40px;
    font-size: 18px;
    margin-bottom: 0;
  }
  @include media575 {
    padding: 20px;
  }
  @include media991 {
    margin-bottom: 15px;
  }
  .w-50 {
    margin-bottom: 0px;
    @include media991 {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.d-md-block {
  @include media991 {
    display: block;
  }
}
.p-sm-0 {
  @include media991 {
    padding: 0px !important;
  }
}
.w-15 {
  width: 15%;
  padding: 0 15px;
  @include media991 {
    width: 100%;
  }
}
.w-85 {
  width: 85%;
  padding: 0 15px;
  @include media991 {
    width: 100%;
  }
}

.btnfilter {
  position: relative;
  .btn,
  .btn:focus,
  .btn:active,
  .btn:visited {
    @include media767 {
      padding: 8px 8px;
      width: 36px;
      height: 34px;
    }
  }
  sup {
    position: absolute;
    top: -9px;
    background: $primary-color;
    color: $light-color;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    vertical-align: middle;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media575 {
      top: -11px;
    }
  }
  img {
    margin-right: 6px;
    @include media767 {
      margin-right: 0;
    }
  }
}
.w-33 {
  width: 33.33%;
  padding: 0 15px;
  @include media991 {
    width: 100%;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.w-25 {
  width: 25%;
  padding: 0 15px;
  @include media991 {
    width: 100%;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

// button shadow
.btnshadow {
  box-shadow: 0px 8px 15px rgba(1, 54, 98, 0.3) !important;
}
// button tabview
.tabviewbtn {
  li {
    list-style-type: none;
    background-color: transparent;
    border: 1px solid $primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 9px 12px;
    border-radius: 8px;
    text-align: center;
    margin-right: 15px;
    cursor: pointer;
    height: 38px;
    @include media1199 {
      padding: 8px 12px;
    }
    @include media480 {
      padding: 8px;
      margin-right: 8px;
      height: 38px;
    }
    &.active {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      span {
        color: $light-color;
        cursor: pointer;
      }
    }
    // &:hover {
    //   background-color: $hover-bg;
    //   border: 1px solid $hover-bg;
    // }
    span {
      @include defaultFontStyle(500, 15, 1.2, $primary-color);
      cursor: pointer;
      @include media1366 {
        font-size: 14px;
      }
      @include media480 {
        font-size: 13px;
      }
      @include media350 {
        font-size: 12px;
      }
    }
  }
}

// disable button
.disabled {
  pointer-events: none;
  user-select: none;
}

// loader
.bc-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  img,
  svg {
    margin: auto;
  }
}

// spray charts tooltip
.sprayChartNoInfoText {
  color: #667784;
  font-size: 15.5px;
  font-family: sans-serif;
}
.sprayChartCls {
  position: relative;
  overflow: hidden;
  padding: 5px;
  margin: 0 auto;

  .arc {
    cursor: pointer;
    opacity: 1;
    &:hover {
      opacity: 0.9;
    }
  }
  .arc-text {
    text-anchor: middle;
    pointer-events: none;
  }
  .tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    border: 1px solid #ccc;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 11px;
    pointer-events: none;
    padding: 2px 5px;
  }
}
.spraychart-section {
  .legends {
    .sprayChartRangeHeader {
      .range {
        width: 130px;
        display: inline-block;
        grid-template-rows: 5px 18px;
        margin-right: 1px;
        .rangeColor {
          height: 5px;
          display: block;
        }
        .rangeText {
          display: block;
          font-size: 12px;
          // text-align: center;
        }
      }
    }
    .legend {
      display: inline-block;

      &Circle {
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
      }
      &Text {
        font-size: 12px;
        font-weight: bold;
        padding-right: 10px;
      }
    }
  }
  .sprayChartHead {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

#sprayChartPrintArea,
#sprayChartPrintAreaAwayBatter {
  .no-groundChart {
    width: 400px;
    margin: 0 auto;
  }
}

// home_batters_charts
.home_batters_charts {
  background-color: $light-color;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 10px 30px rgba(40, 38, 61, 0.03);
  position: relative;
  .w-33 {
    margin-bottom: 30px;
    @include media991 {
      margin-bottom: 15px;
    }
    @include media1599 {
      width: 50%;
      padding: 0 15px;
    }
    @include media1199 {
      width: 100%;
      padding: 0 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
  background-color: $primary-color;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: $light-color;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.anticon {
  color: $light-color;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $bg-red !important;
}
.ant-tooltip-inner {
  background-color: $border-color;
  border-radius: 5px;
  font-weight: 600;
  color: $primary-color;
}
.ant-tooltip-arrow-content {
  background-color: $border-color;
}

#pitchLogPrintArea {
  overflow-x: hidden;
  .table {
    thead.ant-table-thead {
      th.ant-table-column-has-sorters {
        background-color: $table-active;
        color: $black-color;
        border-radius: 0;
        .anticon {
          color: $black-color;
        }
      }
    }
  }
  .ant-fixed-table {
    overflow-x: hidden;
  }
}
.gs-pitchlog-table {
  // .ant-table-cell-fix-left {
  //   @include media575 {
  //     position: static !important;
  //   }
  // }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    @include media767 {
      position: static !important;
    }
  }
  .ant-table-thead {
    tr {
      th {
        &:nth-child(2) {
          position: sticky !important;
          left: 0 !important;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      td {
        &:nth-child(2) {
          position: sticky !important;
          left: 0 !important;
        }
      }
    }
  }
}
// datepicker style

.bc_relative {
  position: relative;
  .react-datepicker-popper[data-placement^="bottom"]{
    z-index: 11;
  }
  .bc_datepicker-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 16px;
    bottom: 8%;
    transform: translateY(-50%);
    @include media480 {
      width: 15px;
      height: 15px;
      bottom: 7px;
    }
    &.active {
      bottom: 35%;
    }
  }
  input {
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px;
    border: 1px solid #e5e9f2;
    border-radius: 4px;
    -webkit-appearance: none;
    appearance: none;
    @include defaultFontStyle(500, 16, 1.2, #28263d);
    @include media480 {
      height: 40px;
      @include defaultFontStyle(400, 14, 1.2, #28263d);
    }
  }
  .react-datepicker {
    &-wrapper {
      width: 100% !important;
    }
    &__triangle {
      left: 50% !important;
      transform: translateY(-50%);
      margin-top: -3px !important;
    }
  }
}
.rb-tool-tip {
  z-index: 9999 !important;
  padding: 4px 10px !important;
  font-size: 12px !important;
}

#hitterSummaryPrintArea {
  table {
    .ant-table-thead {
      th {
        border-top: 1px solid;
      }
      tr {
        &:last-child {
          th {
            background-color: $table-active;
            color: $black-color;
            border-right: 1px solid #dadada;
            svg {
              fill: #000;
            }
          }
        }
      }
    }
  }
}

#tablePrintArea {
  table {
    .ant-table-thead {
      th {
        border-top: 1px solid;
      }
      tr {
        &:last-child {
          th {
            background-color: $table-active;
            color: $black-color;
            border-right: 1px solid #dadada;
            svg {
              fill: #000;
            }
          }
        }
      }
    }
  }
}

.ant-fixed-table {
  .ant-table-thead {
    tr {
      &:first-child {
        th {
          background-color: $primary-color;
          color: $light-color;
          border: 1px solid;
          padding: 4px;
          text-align: center;
          &:first-child {
            &::after {
              box-shadow: none;
            }
          }
        }
      }
      &:nth-child(2) {
        th {
          background-color: $table-active;
          color: #1b1b1b;
          border-right: 1px solid #f6f9fe;
          padding: 4px;
          text-align: center;
          svg {
            fill: $black-color;
          }
        }
      }
    }
  }
  tbody {
    background: #fff;
    td {
      white-space: pre-line;
      text-align: center;
      padding: 16px 10px;
    }
  }
}
.customize-select__menu {
  z-index: 3 !important;
}

// for switch toggle in development
.toggle-switch {
  margin: auto;
  .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
    input {
      display: none;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: $primary-color;
          &:before {
            -webkit-transform: translateX(55px);
            -ms-transform: translateX(55px);
            transform: translateX(55px);
          }
          .off {
            display: none;
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
    &:checked + {
      .slider {
        .on {
          display: block;
        }
      }
    }
  }
  .on {
    display: none;
    color: $light-color;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
    top: 50%;
    left: 57%;
    font-size: 12px;
  }
  .off {
    color: $light-color;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
    top: 50%;
    left: 88%;
    font-size: 12px;
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
  &.pitchar-toggle{
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: absolute;
    top: 10px;
    left: 17px;
    z-index: 1;
    .switch{
      width: 30px;
      height: 15px;
      margin-top: 2px;
    }
    .slider{
      &.active {
        background-color: $gray-dark;
      }
      &::before{
        height: 11px;
        width: 11px;
        left: 2px;
        bottom: 2px;
      }
    }
   
    input {
      &:checked {
        + {
          .slider {
            &:before {
              -webkit-transform: translateX(14px);
              -ms-transform: translateX(14px);
              transform: translateX(14px);
            }
            .off {
              display: none;
            }
          }
        }
      }
    }
    .toggle-icon{
      padding: 0 3px;
    }
  }
}

.custom-left-fixed {
  table {
    tr {
      td {
        &:first-child {
          position: sticky;
          left: 0;
        }
      }
      th {
        &:first-child {
          position: sticky;
          left: 0;
        }
      }
    }
  }
}

.customize-select {
  .css-1uccc91-singleValue {
    @include defaultFontStyle(500, 15, 1.2, $light-black);
  }
  // svg {
  //   fill: $black;
  // }
}

.highcharts-tooltip{
  &:hover {
    span {
      // display: block!important;
      visibility: visible !important;
    }
  } 
}

.main-container{
  max-width: 1600px;
  margin: 0 auto;
  background-color: #F6F9FE;
}

.details {
  .printpagemain{
    padding: 30px 20px 20px;
    padding-bottom: 40px;
    .profile-logo{
      display: flex;
      justify-content: space-between;
      img{
        width: 50px;
        height: 50px;
        margin: 0;
        object-fit: cover;
      }
    }
    .filtersection{
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .logo{
    width: 160px;
    float: left;
  }
}


/* popup css */
.modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  &.is-visible {
      visibility: visible;
      .modal-overlay {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
      }
      .modal-wrapper {
          &.modal-transition {
              opacity: 1;
          }
      }
  }
  .modal-overlay {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: hsla(0, 0%, 0%, 0.5);
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 0.3s, opacity 0.3s;
      // pointer-events: none;
  }
  .modal-wrapper {
      position: absolute;
      z-index: 9999;
      top: 30px;
      right: 30px;
      max-width: 483px;
      width: 90%;
      box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      @include media575 {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) !important;
        right: unset;
      }

      .modal-content {
          .details {
           
            .printpagemain{
              padding-bottom: 30px;
              max-width: 413px;
              margin: auto;
            }
            .logo{
              width: 90px;
              float: left;
            }
          }
      }
      &.modal-transition {
          opacity: 0;
      }
      .modal-close {
        position: absolute;
        top: -14px;
        right: -10px;
        padding: 1em;
        color: #000;
        border: 0;
        cursor: pointer;
        background: #d8d8d8;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
      }
  }
}

.table-video-icon{
  display: flex;
  align-items: center;
  justify-content: space-around;
  .video-disabled{
    cursor: not-allowed;
    pointer-events: unset;
  }
  i{
    color: #b8b8b8;
    margin-right: 10px;
    cursor: pointer;
  }
}


.time-line-toggle {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  position: absolute;
  top: 27px;
  right: 17px;
  z-index: 1;

  @include media575 {
  position:static;
  justify-content: center;
  margin-top: 20px;
  }
  .time-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    flex-wrap: wrap;


    .timeline-scoll {
      .timeline-inner {
        display: flex;
        // margin: 7px 0 0;
        @include media575 {
          margin: 17px 0 0;
        }
        div {
          list-style: none;
          position: relative;
          width:38px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;


          p {
            @include defaultFontStyle(600, 15, 1.1, #000000);
            margin: 0;
            position: absolute;            
            z-index: 99999;
            font-size: 18px;
            top: -24px;
            &.img{
              top: -7px;
              font-size: 15px;
              img{
                margin: -19px 0 0;
                max-width: 16px;
                width: 100%;
              }
            }            
          }


          span {
            margin-bottom: 7px;
            white-space: nowrap;
            font-size: 13px;
            color: #D9D9D9;
          }


          a {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 3;
            background: #D9D9D9;
            border: 1px solid #D9D9D9;
          }


          &.active {
            &:after {
              background: #013662;
              border: 2px solid #013662;
            }


            a {
              background: #013662;
              border: 2px solid #013662;
            }
          }


          &.select {
            a {
              background: #FFFFFF;
              border: 1px solid #D9D9D9;
            }
          }


          &.disable {
            a {
              background: #E2E2E2;
              cursor: not-allowed;
            }
          }


          &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 6px;
            background: #31313d29;
            content: "";
            width: 71%;
            right: -13px;
          }


          &:last-child {
            &:after {
              content: "";
              display: none;
            }
          }
        }
      }
    }
  }
}

.tooltip-pitch {
    background: #fff !important;
    width: 90px !important;
    height: 90px !important;
    opacity: 1 !important;
    padding: 0 !important;
    img{
      width: 100% !important;
      height: 100% !important;
    }
}

.profiledashboard {
    padding-bottom: 30px;
    // @include media575{
    //     padding-top: 92px;
    // }
    .common-charts{
        @include media575{
            padding: 20px 0;
        }
    }
    .profilecard {
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        padding: 15px;
        @include media767 {
            margin-bottom: 15px;
        }
        h3 {
            margin-bottom: 8px;
            @include defaultFontStyle(700, 18, 1.4, $black-color);
        }
        h4 {
            color: $light-black;
            font-weight: 500;
        }
        .profile {
            margin-bottom: 10px;
            position: relative;
            .profilelogo {
                border-radius: 100%;
                border: 2px solid $primary-color;
                width: 100px;
                height: 100px;
                padding: 2px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }
            .editicon {
                position: absolute;
                bottom: 8px;
                right: 2px;
                border-radius: 50%;
                display: none;
                // TO DO
                // display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-color;
                border: 2px solid $primary-color;
                padding: 5px;
            }
        }
    }
    .slider_card {
        padding: 0;
        @include media767 {
            padding: 15px 0;
        }
        .slick-list {
            .slider-card > a {
                .d-flex {
                    padding-bottom: 0px;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .tabviewbtn {
        li {
            list-style-type: none;
            text-align: center;
            @include media1366 {
                padding: 8px;
                margin-right: 6px;
            }
            @include media1199 {
                height: 100%;
                padding: 10px;
            }
            @include media991 {
                display: inline-flex;
                margin-bottom: 10px;
                width: 32%;
                align-items: center;
                margin-right: 7px;
                justify-content: center;
                padding: 10px 0;
            }
            @include media575 {
                display: inline-flex;
                margin-bottom: 10px;
                width: 48%;
                margin-right: 5px;
                align-items: center;
            }
            &.active {
                a {
                    @include media480 {
                        font-size: 11px;
                        border-radius: 4px;
                        margin-right: 10px;
                    }
                }
            }
            a {
                @include media1199 {
                    font-size: 13px;
                }
                @include media991 {
                    width: 100%;
                }
                @include media767 {
                    font-size: 12px;
                }
                @include media480 {
                    font-size: 11px;
                    border-radius: 4px;
                    margin-right: 10px;
                }
            }
        }
    }
    .tabshead {
        display: flex;
        align-items: center;
        margin: 20px 0;
        justify-content: space-between;
        @include media991 {
            display: block;
            margin: 15px 0;
        }
        .tabviewbtn {
            @include media991 {
                display: block;
                text-align: center;
            }
        }
        .btnfilter {
            @include media991 {
                justify-content: flex-start;
                margin-top: 15px;
            }
        }
        .lefe-right-page {
            @include media991 {
                text-align: right;
                margin-right: 17px;
            }
            .blue {
                padding: 8px 13px;
                @include media991 {
                    padding: 7px 13px;
                }
            }
            i {
                font-size: 16px;
            }
            .disable {
                background-color: $light-black;
                border-color: $light-black;
                cursor: not-allowed;
                i {
                    color: $light-color;
                }
                &.hover {
                    color: $light-color;
                    i {
                        color: $light-color;
                    }
                }
            }
        }
    }
    .editorview {
        padding: 20px;
        .quill {
            background-color: $light-color;
            border-radius: 8px;
        }
        .ql-toolbar.ql-snow {
            border: 0;
            padding-top: 0;
            .ql-picker-label {
                border: 1px solid $border-color;
                border-radius: 3px;
                padding: 0px 3px;
                margin: 0 3px;
            }
        }
        .ql-container.ql-snow {
            border: 1px solid $border-color;
            border-radius: 8px;
        }
        .ql-editor {
            min-height: 300px;
        }
        .ql-snow.ql-toolbar {
            button {
                border: 1px solid $border-color;
                border-radius: 3px;
                padding: 2px 3px;
                margin: 0 3px;
            }
        }
        .ql-snow {
            .ql-toolbar {
                button {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 2px 3px;
                    margin: 0 3px;
                }
            }
            .ql-color-picker {
                .ql-picker-label {
                    padding: 1px 2px;
                }
                margin: 0 3px;
            }
            .ql-icon-picker {
                .ql-picker-label {
                    padding: 1px 2px;
                }
                margin: 0 3px;
            }
            .ql-picker.ql-header {
                margin: 0 5px;
            }
        }
        .ql-formats {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
    .profileinfo {
        @include media767 {
            display: block;
        }
        @include media991 {
            .profilecard {
                margin-right: 15px;
            }
        }

        .w-15 {
            @include media1429 {
                width: 20%;
            }
            @include media1199 {
                width: 30%;
            }
            @include media767 {
                width: 100%;
            }
        }
        .w-85 {
            @include media1429 {
                width: 80%;
            }
            @include media1199 {
                width: 70%;
            }
            @include media767 {
                width: 100%;
            }
        }
    }
    .bubblechart .highcharts-legend {
        display: none;
    }

    .heatmap {
        #hartMapChart {
            margin: 0 auto;
            max-width: 500px;
        }
    }
    .filterinfo {
        .select_box {
            .d-inline-flex {
                @include media575 {
                    display: block;
                }
                .w-100 {
                    flex: auto;
                    padding: 0;
                }
            }
            .mr-15 {
                @include media575 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .rc-slider {
        display: flex;
        height: 45px;
        align-items: center;
        margin: 0 8px;
        .rc-slider-handle {
            border: solid 5px $primary-color;
            width: 16px;
            height: 16px;
            margin-top: 0px;
        }
        .rc-slider-track {
            background-color: $primary-color;
            height: 6px;
        }
        .rc-slider-step {
            height: 6px;
        }
        .rc-slider-tooltip-inner {
            background-color: $black-color;
            padding: 2px;
            min-width: 35px;
            height: 22px;
            @include defaultFontStyle(500, 14, 1.4, $light-color);
        }
        .rc-slider-tooltip-arrow {
            border-top-color: $black-color;
        }
        .rc-slider-tooltip-placement-top {
            top: -23px;
        }
        .rc-slider-handle-dragging {
            border-color: $primary-color !important;
            box-shadow: none !important;
        }
    }

    #strikeZoneChart{
        width: 100% !important;
        height: 433px !important;
        @include media575 {
            width: 100% !important;
        }
    }
    #allPitchStrikeZone,
    #swingsStrikeZone,
    #whiffsZoneChart,
    #strikeZone_FB,
    #strikeZone_DB,
    #strikeZone_RB,
    #strikeZone_CB,
    #strikeZone_SB,
    #strikeZone_CH,
    #strikeZone_DC,
    #strikeZone_SI,
    #strikeZone_CT,
    #strikeZone_SL,
    #strikeZone_SP,
    #strikeZone_KN{
        width: 100% !important;
        height: 425px !important;
        @include media575 {
            width: 100% !important;
        }
    }

    #releasePointChart,
    #sideReleaseChart {
        width: 100% !important;
        height: 470px !important;
        @include media575 {
            width: 100% !important;
        }
    }
    #hartMapChart {
        width: 100% !important;
        height: 425px !important;
        @include media575 {
            width: 100% !important;
        }
    }
    #pitchSequencingChart,
    #columnChart,
    #releasePointChart,
    #sideReleaseChart {
        @include customMedia(1279) {
            width: 400px;
        }
        @include media575 {
            width: 100% !important;
        }
    }
    #columnChart {
        svg {
            @include media575 {
                width: 100%;
            }
        }
    }
    #groundChart,
    #contourGraphChart,
    #breakMovementChart,
    #no-groundChart {
        // width: 400px;
        margin: 0 auto;
        @include media575 {
            width: 100%;
        }
    }
    #no-groundChart {
        height: 469px;
        display: flex;
        align-items: center;
    }
    #splineVerticalChart,
    #splineIndVerticalChart,
    #splineHorizontalChart {
        @include customMedia(1279) {
            width: 408px;
        }
        @include media575 {
            width: 100%;
        }
    }

    .common-charts {
        .row {
            max-width: 1315px;
            margin: 0 auto;
            &.HeatMap-row{
                max-width: 100%;
               
                
                #PitchPercHeatMapChart,
                #PitchCountHeatMapChart,
                #SwingPercHeatMapChart,
                #SwingCountHeatMapChart,
                #WhiffPercHeatMapChart,
                #WhiffCountHeatMapChart,
                #BattedBallsHeatMapChart,
                #AvgEVHeatMapChart,
                #HardHitPercHeatMapChart,
                #HardHitCountHeatMapChart,
                #DamagePercHeatMapChart,
                #DamageCountHeatMapChart,
                #GBPercHeatMapChart,
                #LDPercHeatMapChart,
                #FBPercHeatMapChart,
                #PUPercHeatMapChart,
                #AvgVelocityHeatMapChart,
                #AvgSpinRateHeatMapChart,
                #AvgTiltHeatMapChart,
                #AvgLAHeatMapChart,
                #EfficiencyHeatMapChart,
                #VBreakHeatMapChart,
                #InducedVBreakHeatMapChart,
                #HBreakHeatMapChart,
                #VApproachHeatMapChart,
                #HApproachHeatMapChart,
                #VReleaseAngleHeatMapChart,
                #HReleaseAngleHeatMapChart {
                    width: 100% !important;
                    height: 444px !important;
                }
                .w-25 {
                    @include customMedia(1279) {
                      width: auto;
                    }
                    @include media575 {
                        width: 100% !important;
                      }
                    
                }
              
                .ground-chart {
                    @include customMedia(1279) {
                      width: 408px;
                      margin: 0 auto;
                    }
                    @include media575 {
                      width: 100% !important;
                    }
                }
                @include customMedia(1279) {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    overflow: auto;
                }
                @include media575 {
                    display: block;
                    flex-wrap: wrap;
                    flex-direction: column;
                    overflow-y: hidden;
                }
                
            }
            @include customMedia(1279) {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                overflow: auto;
            }
            @include media575 {
                display: block;
                flex-wrap: wrap;
                flex-direction: column;
                overflow-y: hidden;
            }
            .w-33 {
                @include customMedia(1279) {
                    width: auto;
                }
                @include media575 {
                    width: 100% !important;
                }
                .ground-chart {
                    @include customMedia(1279) {
                        width: 408px;
                        margin: 0 auto;
                    }
                    @include media575 {
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .btnprint{
        .btn{
            margin-left: 12px;
            padding: 7px 10px;
            @include media767 {
                padding: 5px;
            }
            img{
                margin-right: 0;
                width: 25px;
            }
        }
    }
}
// .common-charts {
//     .row{
//         display: flex;
//         flex-wrap: nowrap;
//         flex-direction: row;
//         overflow-y: scroll;
//         .w-33{
//             width: 100%;
//             @include media575{
//                 width: auto;
//             }
//         }
//     }
// }
.graph-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media575 {
        display: block;
    }
    &.pitch-outcomes{
        justify-content: center;
       
    }
    .legends {
        width: 100%;
    }
    .custom-select {
        background: url("../../assets/images/common/down_arrow_white.svg") no-repeat 94% center $primary-color;
        box-shadow: none;
        height: 45px;
        width: 100%;
        padding: 7px 22px 8px 10px;
        border: 1px solid #d8d8d8;
        border-radius: 50px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        font-style: normal;
        @include defaultFontStyle(500, 13, 1.2, $light-color);
        @include media575 {
            height: 38px;
        }
        option {
            background-color: $light-color;
        }
    }
    .refresh {
        margin-left: auto;
        img {
            width: 20px;
        }
    }
    .select-group {
        display: flex;
        margin-top: 10px;
    }
    .select-box {
        margin-right: 6px;
        @include media575 {
            margin-top: 10px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    
}
.single-select{
    .graph-select{
        justify-content: flex-end;
    }
}
.contactTabSpray1 {
    height: 518px;
    .chartdownload {
        position: absolute;
        bottom: 11px;
    }
}
.fixed-h {
    .fixed-s-l {
        height: 100px;
    }
}

.btn-view {
    padding: 4px 10px;
    font-size: 14px;
    &:focus{
        padding: 4px 10px;
        font-size: 14px;
    }
}
.chartdownload{
    .btn{
        &.white{
            padding: 2px 7px;
            border-radius: 5px;
        }
    }
    i{
        color: #000;
        font-size: 14px;
    }
}
.hisrogram{
    .row{
        flex-wrap: wrap !important;
    }
    .w-100{
        padding: 0 15px
    }
}

.histogram_dropdown{
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 12px;
    margin-top: 10px;
    .w-25{
        width: 25%;
        @include media767{
            width: 50%;
        }
        @include media575{
            width: 100%;
        }
    }
}

#allPitchCountour,
#swingsCountour,
#whiffsCountour,
#countour_FB,
#countour_DB,
#countour_RB,
#countour_CB,
#countour_SB,
#countour_CH,
#countour_DC,
#countour_SI,
#countour_CT,
#countour_SL,
#countour_SP,
#countour_KN,
#contourGraphChart
{
    svg{
        max-width: 100%;
        width: 100%;
    }
}

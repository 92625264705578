.main-dashboard {
  padding-bottom: 0;
}

.slider_card {
  padding: 5px 5px 0 5px;
  overflow: hidden;

  .slick-list {
    overflow-x: scroll;
    padding-bottom: 5px;

    .slider-card {
      >a {
        width: 223px;
        margin: 5px 15px;
        box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media1599 {
          width: 210px;
        }

        @include media1199 {
          width: 200px;
        }

        @include media575 {
          width: 170px;
        }

        .price-title {
          background: url("../../assets/images/dashborad/blue_bg.svg") 50% no-repeat;
          width: 120px;
          height: 40px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          @include defaultFontStyle(500, 15, 1.2, $light-color);

          @include media767 {
            font-size: 14px;
            background-position: 0 -9px;
            height: 32px;
          }
        }

        .d-flex {
          align-items: center;
          justify-content: space-around;
          margin: 3px 0;
          position: relative;
          padding-bottom: 3px;

          @include media1599 {
            margin: 10px 0;
          }

          span {
            @include defaultFontStyle(500, 20, 1.2, $light-black);

            @include media767 {
              font-size: 16px;
            }
          }

          h5 {
            @include defaultFontStyle(700, 35, 1.2, $black-color);
            margin: 0 10px;

            @include media767 {
              font-size: 24px;
            }
          }

          &:before {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            height: 2px;
            background-color: $primary-color;
            width: 20px;
            content: "";
            bottom: 0;
          }
        }

        p {
          display: block;
          text-align: center;
          margin-bottom: 5px;
          padding: 0 8px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 60px);
          overflow: hidden;
          @include defaultFontStyle(500, 15, 1.4, $primary-color);

          @include media767 {
            font-size: 14px;
          }
        }

        .major_logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          margin-bottom: 10px;

          img {
            border-radius: 8px;
          }

          @include media575 {
            height: 30px;
            width: 30px;
            margin-bottom: 5px;
          }
        }

        &:hover,
        &.active {
          box-shadow: 0px 0px 0px 2px rgba(1, 54, 58, 0.8);
        }
      }

      &:first-child {
        a {
          margin-left: 3px;
        }
      }

      &:last-child {
        a {
          margin-right: 3px;
        }
      }
    }
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-color;
    background-color: $light-color;
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;
    background-color: $light-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $light-black;
    border: 2px solid $light-black;
    border-radius: 5px;

    &:hover {
      background-color: $light-black;
      border: 2px solid $light-black;
    }
  }
}

.button_hitters_fixed {
  .navbar_fixed {
    border-bottom: none;
  }

  .button_hitters {
    // width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    background: #ffffff;
    border-bottom: 2px solid #f6f9fe;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 95px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: width 0.1s ease, left 0.1s ease;
    padding: 4px 30px 11px 30px;
    margin-bottom: 0;

    @include media991 {
      position: static;
      // margin-bottom: 15px;
      padding: 0;
      background-color: transparent;
      padding: 15px 20px;
    }

    .btn {
      margin-right: 10px;

      @include media480 {
        margin-right: 5px;
      }

      &:hover {
        background-color: $primary-color;
        color: $light-color;
        border: 1px solid $primary-color;
      }
    }
  }

  .select-box {
    .custom-select {
      background: url("../../assets/images/common/gray_down_arrow.svg") no-repeat 90% center $light-color;
      box-shadow: none;
      height: 40px;
      width: 100%;
      padding: 10px 65px 10px 15px;
      border: 1px solid $black-color;
      border-radius: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      appearance: none;
      @include defaultFontStyle(500, 15, 1.2, $black-color);

      @include media575 {
        font-size: 14px;
        height: 38px;
        padding: 10px 46px 10px 15px;
      }

      @include media480 {
        padding: 10px 30px 10px 15px;
      }

      &:hover,
      &:visited,
      &:active,
      &:focus {
        border: 1px solid $black-color;
        color: $black-color;
        outline: none;
      }

      option {
        @include defaultFontStyle(500, 15, 1.2, $light-black);
      }
    }
  }

  .list-group {
    padding-top: 150px;

    @include media991 {
      padding-top: 0;
    }
  }

  .sidebar-content {
    .content {
      height: calc(100vh - 112px);
      // margin-top: 70px;
      margin-top: 0;

      @include media991 {
        height: calc(100vh - 190px);
      }

      @include media575 {
        height: calc(100vh - 260px);
      }

      >section {
        .container-fluid {
          padding: 5px 0 0;
        }
      }
    }
  }

  .main-div {
    margin-top: 150px;
    // height: calc(100vh - 150px);
    // height: 100%;

    @include media991 {
      margin-top: 70px;
      margin-bottom: 50px;
      height: 100vh;
    }

    @include media575 {
      margin-top: 140px;
      margin-bottom: 50px;
      // height: calc(100vh - 140px);
    }

    // @include media480 {
    //   margin-bottom: 100px;
    //   margin-top: 60px;
    //   height: calc(100vh - 90px);
    // }
  }

  .menu-toggle {
    display: none !important;
  }

  .page-content-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

.dashboard_feature {
  padding-bottom: 10px;

  @include media767 {
    overflow-x: auto;
  }

  h3 {
    padding-top: 0.5rem;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }

  .select_box {
    justify-content: space-around;
    padding: 17px 0 3px 0;
    max-width: 1140px;
    margin: 0 auto;

    @include media991 {
      padding: 0 10px;
    }

    .select_box_inner {
      width: 220px;

      @include media991 {
        width: 100%;
        padding-bottom: 15px;
      }

      select {
        height: 35px;
        font-size: 13px;
        padding: 5px;

      }
    }

  }

  table {
    width: 100%;

    tr {
      border-bottom: 2px solid $bg-color;

      td {
        padding: 15px 0;

        @include media767 {
          padding: 15px 0;
        }

        p {
          @include defaultFontStyle(400, 15, 1.4, $light-black);
          padding: 0px 15px;
          position: relative;
          transition: all 0.5s ease 0s;
          white-space: nowrap;

          @include media767 {
            font-size: 14px;
          }

          &:before {
            position: absolute;
            left: 0;
            height: 100%;
            width: 2px;
            background-color: $light-black;
            content: "";
            top: 0;
            bottom: 0;
            transition: all 0.5s ease 0s;
          }

          &.active,
          &:hover {
            transition: all 0.5s ease 0s;
            color: $black-color;

            &::before {
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }

  .team_insights_sec {
    padding: 10px 0;
    justify-content: center;


    >div {
      padding: 5px 5px 20px;

      .tabledashed {
        border-spacing: 0;
        min-width: 610px;

        thead {
          tr {
            background-color: $primary-color;

            th {
              background-color: unset;
              border-right: none;
              padding: 5px 7px !important;
              font-size: 13px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 4px 7px !important;
              font-size: 13px;
              border-right: 1px solid $table_border;

              &:first-child {
                border-left: 1px solid $table_border;
                border-radius: unset;
                position: unset;
                position: relative;

                .tooltip-pitch {
                  width: 60px !important;
                  height: 60px !important;
                  opacity: 1 !important;
                  padding: 0 !important;

                  img {
                    width: 100% !important;
                    height: 100% !important;
                  }
                }

              }

              &:last-child {
                border-right: 1px solid $table_border;
                border-radius: unset;
              }
            }

            &:hover {
              background-color: $highlight_color,
            }

            &:first-child {
              td {
                &:first-child {
                  background: $table-active;
                  position: unset;
                }
              }
            }

            &:last-child {
              td {
                border-bottom: 1px solid $table_border;
                border-radius: unset;
              }
            }
          }
        }
      }

      &:first-child {
        padding-right: 15px;
      }

      &:last-child {
        padding-left: 15px;
      }
    }

    @include customMedia(1400) {
      overflow-x: auto;
      justify-content: unset;
    }

    @include customMedia(630) {
      overflow-x: unset;
      flex-wrap: wrap;
      margin: 5px 5px 20px;
      >div {
        overflow-x: auto;
        padding: unset;
        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;
          padding-top: 20px;
        }
        .tabledashed{
           tbody {
            tr {
              &:first-child{
                td{
                  &:nth-child(1){
                    position: sticky;
                  }
                }
              }
               td{
                &:nth-child(1){
                  position: sticky;
                  background: #fff;
                  z-index: 0;
                  left: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar-content {
  display: flex;

  @include media991 {
    display: block;
  }

  .sidebar {
    width: 240px;
    background-color: #ffffff;
    position: fixed;

    @include media991 {
      width: 100%;
      position: static;
      background-color: transparent;
    }

    .list-group {
      overflow-y: auto;
      height: calc(100vh - 90px);
      padding-top: 0;
      padding-bottom: 55px;

      // @include media1429 {
      //   padding-top: 100px;
      // }
      @include media991 {
        padding: 0;
        display: flex;
        height: auto;
        margin: 0 20px;
        background-color: #ffffff;
      }

      // @include media480 {
      //   height: calc(100vh - 60px);
      // }
      li {
        display: block;
        background-color: transparent;
        transition: all 0.5s ease 0s;

        a {
          position: relative;
          display: block;
          padding: 16px 15px;
          text-align: left;
          margin: 0 20px;
          text-transform: capitalize;
          border-bottom: 2px solid $bg-color;
          transition: all 0.5s ease 0s;
          white-space: nowrap;
          @include defaultFontStyle(500, 15, 1.2, $light-black);

          @include media1429 {
            padding: 15px 10px;
            margin: 0 15px;
            font-size: 15px;
          }

          @include media767 {
            margin: 0px;
          }

          @include media991 {
            border-bottom: none;
          }
        }

        &.active {
          pointer-events: none;
          user-select: none;
        }

        &.active,
        &:hover {
          @include backgroundOpacity($primary-color, 0.09);

          a {
            color: $primary-color;
            font-weight: 500;
          }
        }
      }

      &.game-summary-sidebar {
        height: calc(100vh - 96px);

        @include media991 {
          padding: 0;
          display: flex;
          height: auto;
          margin: 20px 20px 0;
          background-color: #ffffff;
        }
      }
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $light-color;
      background-color: $light-color;
      border-radius: 5px;
    }

    ::-webkit-scrollbar {
      height: 1px;
      border-radius: 5px;
      background-color: $light-color;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $light-black;
      border: 2px solid $light-black;
      border-radius: 5px;

      &:hover {
        background-color: $light-black;
        border: 2px solid $light-black;
      }
    }
  }

  .content {
    width: calc(100% - 240px);
    margin-left: 240px;
    height: 90vh;
    overflow-x: hidden;

    @include media991 {
      margin-left: 0;
      width: 100%;
    }

    @include media767 {
      height: 72vh;
      overflow-y: auto;
    }

    >section {
      padding: 5px;

      .container-fluid {
        padding: 10px 0 0 0;
      }
    }
  }

  &.scrimmage-content {
    .sidebar {
      width: 195px;

      @include media991 {
        width: 100%;
        position: static;
        background-color: transparent;
      }
    }

    .content {
      width: calc(100% - 195px);
      margin-left: 195px;

      @include media991 {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.gamesummary-sidebar {
  .page-content-wrapper {
    width: 100%;
    margin-left: 0;

    .main-div {
      @include media991 {
        margin-top: 70px;
        height: calc(100vh - 70px);
      }

      @include media575 {
        margin-top: 140px;
        height: calc(100vh - 140px);
      }
    }
  }

  .sidebar {
    @include media991 {
      margin-top: 20px;
    }
  }

  .menu-toggle {
    display: none !important;
  }
}

.pitchers-summary {
  .page-content-wrapper {
    width: calc(100%);
    margin-left: 0;
  }

  .sidebar {
    @include media991 {
      margin-top: 20px;
    }

    .list-group {
      height: calc(100vh - 94px);

      @include media991 {
        height: auto;
      }
    }
  }

  .menu-toggle {
    display: none !important;
  }

  .main-div {
    @include media991 {
      margin-top: 60px;
      margin-bottom: 50px;
      height: calc(100vh - 60px);
    }

    @include media575 {
      margin-top: 135px;
      margin-bottom: 50px;
      height: calc(100vh - 135px);
    }
  }
}

.hittersummary-sidebar {
  .page-content-wrapper {
    width: calc(100%);
    margin-left: 0;
  }

  .sidebar {
    padding-top: 94px;

    @include media991 {
      // margin-top: 20px;
    }

    @include media575 {
      padding-top: 140px;
      margin-top: 20px;
    }

    .list-group {
      height: calc(100vh - 94px);

      @include media991 {
        height: auto;
      }
    }
  }

  .menu-toggle {
    display: none !important;
  }

  .main-div {
    @include media991 {
      margin-top: 00px;
      margin-bottom: 50px;
      height: calc(100vh - 140px);
    }

    @include media575 {
      margin-top: 15px;
      margin-bottom: 50px;
      height: calc(100vh - 212px);
    }
  }
}
